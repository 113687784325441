import React, { useEffect, useState } from "react";
import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Typography,
  IconButton,
  useTheme,
  InputLabel,
  TextField,
  Grid,
  MenuItem,
  Button,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@mui/material";
import { CheckCircle, Cancel, Autorenew } from "@mui/icons-material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Colors from "../../../assets/styles";
import orderServices from "../../../api/orderServices/order.index";
import Loader from "../../../components/Loader";
import { useForm } from "react-hook-form";
import SimpleDialog from "../../../components/Dialog";
import VoucherServices from "../../../api/VoucherServices/index";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const VoucherList = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortedRows, setSortedRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectStatus, setSelectStatus] = useState();
  const [selectedUser, setSelectedUser] = useState("");
  const [collections, setColletions] = useState([]);
  const [count, setCount] = useState(0);

  // State for dialog
  const [open, setOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  // Open the confirmation dialog
  const handleDeleteClick = (row) => {
    setRowToDelete(row);
    setOpen(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    setRowToDelete(null);
  };

 

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log(baseUrl);

  const tableHead = [
    { name: "code", label: "Code" },
    { name: "discount_type", label: "Discount Type" },
    { name: "discount_value", label: "Discount Value" },
    { name: "min_order_value", label: "Min Order Value" },
    { name: "start_date", label: "Start Date" },
    { name: "end_date", label: "End Date" },
    { name: "is_active", label: "Status" },
    { name: "action", label: "Action" },
  ];

  useEffect(() => {
    handleGetCollections(page  ,rowsPerPage);
  }, []);

  useEffect(() => {
    handleSort(orderBy, order);
  }, [collections]);

  const handleGetCollections = async (newPage , rowsPerPage) => {
    setLoading(true);
    try {
      const { data, responseCode, message } =await VoucherServices.voucherList(newPage == 0 ?1 :newPage,rowsPerPage);
      console.log(data?.vouchers);
      setColletions(data.vouchers);
      setCount(data?.total_count)
      setLoading(false);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  
  const handleDeleteCategory = async () => {
    setLoading(true);
   
    try {
      const { data, responseCode, message } = await VoucherServices.deleteVoucher(rowToDelete?._id);
      handleGetCollections(page ,rowsPerPage);
      handleClose();
      SuccessToaster(message)
    } catch (error) {
      console.error("Error while fetching users:", error);
      ErrorToaster(error)
    }
  };
  const handleSort = (column, direction) => {
    const isAsc = direction === "desc";
    const sorted = [...collections].sort((a, b) => {
      let aValue = a[column];
      let bValue = b[column];

      // Handle nested properties
      if (column === "customer") {
        aValue = `${a.customer.first_name} ${a.customer.last_name}`;
        bValue = `${b.customer.first_name} ${b.customer.last_name}`;
      } else if (column === "details") {
        aValue = a.details
          .map((detail) => `${detail.size} ${detail.qty} ${detail.design}`)
          .join(", ");
        bValue = b.details
          .map((detail) => `${detail.size} ${detail.qty} ${detail.design}`)
          .join(", ");
      }

      if (aValue < bValue) {
        return isAsc ? -1 : 1;
      }
      if (aValue > bValue) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    console.log(direction);
    setSortedRows(sorted);
    setOrder(direction);
    setOrderBy(column);
  };

  const handleSortRequest = (column) => {
    const isAsc = orderBy === column && order === "asc";
    handleSort(column, isAsc ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    const updatedPage = newPage 
    setPage( updatedPage);
    handleGetCollections(updatedPage ,rowsPerPage)
  };
  const handleChangePage1 = (event, newPage) => {
    const updatedPage = newPage  + 1
    setPage( updatedPage);
    handleGetCollections(updatedPage ,rowsPerPage)
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case "Paid":
        return <CheckCircle style={{ color: "green" }} />;
      case "Canceled":
        return <Cancel style={{ color: "red" }} />;
      case "Refunded":
        return <Autorenew style={{ color: "grey" }} />;
      case "pending":
        return <Autorenew style={{ color: "grey" }} />;
      default:
        return null;
    }
  };
  useEffect(() => {
    if (selectedUser) {
      console.log(selectedUser);
      setValue("status", selectedUser.status);
      setSelectStatus(selectedUser.status);
    }
  }, [openDialog, selectedUser, setValue]);

  const handleStatusChange = (event) => {
    setSelectStatus(event.target.value);
    setValue("status", event.target.value);
  };
  const handleOpenDialog = (orderDetail) => {
    console.log(orderDetail);
    setOpenDialog(true);
    setSelectStatus(orderDetail.status);
    setSelectedUser(orderDetail);
  };

  const updateStatus = async (formData) => {
    let obj = {
      order_id: selectedUser?._id,
      status: formData?.status,
    };
    console.log(obj);
    try {
      const data = await VoucherServices.updateVoucher(obj);
      setLoading(true);
      SuccessToaster(data.message);
      setOpenDialog(false);
      handleGetCollections(page ,rowsPerPage);
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Paper
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.02) 0px 3.5px 5.5px",
          borderRadius: "15px",
        }}
      >
        <TableContainer sx={{ mt: 12 }}>
          <Box sx={{ m: 2, display: "flex", justifyContent: "end" }}>
            <Button
              onClick={() => navigate("/create-voucher")}
              sx={{
                backgroundColor: "#0ea5ea",
                color: "white",
                ":hover": {
                  backgroundColor: "#0ea5ea", // Keeps the same background color on hover
                  color: "white", // Keeps the text color the same
                },
              }}
              color="primary"
            >
              Create Vouchers
            </Button>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((cell) => (
                  <TableCell key={cell.name}>
                    <TableSortLabel
                      active={orderBy === cell.name}
                      direction={orderBy === cell.name ? order : "asc"}
                      onClick={() => handleSortRequest(cell.name)}
                      sx={{
                        svg: {
                          fontSize: "12px",
                          color: `${Colors.grey} !important`,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.grey,
                          fontSize: "12px",
                          fontWeight: 700,
                          textTransform: "uppercase",
                        }}
                      >
                        {cell.label}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={tableHead?.length + 1}>
                    <Loader width="40px" height="40px" />
                  </TableCell>
                </TableRow>
              ) : (
                sortedRows.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>{row?.code}</TableCell>
                      <TableCell>{row?.discount_type}</TableCell>
                      <TableCell>{row?.discount_value}</TableCell>
                      <TableCell> {row?.min_order_value} </TableCell>
                      <TableCell>{moment(row?.start_date).format("DD-MM-YYYY")}</TableCell>
                      <TableCell>{moment(row?.starend_date_date).format("DD-MM-YYYY")}</TableCell>
                      <TableCell><Typography sx={{color:row?.is_active == true ? "green":"red"}}>{row?.is_active == true ? "Active":"Inactive"}</Typography></TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <Box
                            component={"div"}
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/update-voucher`, { state: row })
                            }
                          >
                            <EditIcon />
                          </Box>
                          <Box
                            component={"div"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick(row)}
                          >
                            <DeleteIcon />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>

          {/* Confirmation Dialog */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle id="confirm-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="confirm-dialog-description">
                Are you sure you want to delete this item?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                No
              </Button>
              <Button
                onClick={handleDeleteCategory}
                color="secondary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </TableContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Typography>
            Showing 
            {Math.min((page + 1) * rowsPerPage, count)} of{" "}
            {count} entries
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            {page !== 0 && (
              <IconButton
                sx={{ border: "1px solid grey", p: 1 }}
                onClick={(e) => handleChangePage(e, page  - 1)}
                disabled={page === 0}
              >
                <KeyboardArrowLeft />
              </IconButton>
            )}
            <Box sx={{ display: "flex", gap: "5px" }}>
              {[
                ...Array(Math.ceil(count / rowsPerPage)).keys(),
              ].map((pageNum) => (
                <Box
                  style={{
                    border: "1px solid grey",
                    p: 1,
                    borderRadius: "50%",
                    minWidth: "40px",
                    minHeight: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    backgroundColor: page === pageNum +1 ? Colors.primary : "",
                    color: page === pageNum +1? Colors.white : Colors.black,
                  }}
                  key={pageNum}
                  onClick={(e) => handleChangePage1(e, pageNum)}
                >
                  {pageNum + 1}
                </Box>
              ))}
            </Box>
            {page !== Math.ceil(count / rowsPerPage) - 1 && (
              <IconButton
                sx={{ border: "1px solid grey", p: 1 }}
                onClick={(e) => handleChangePage(e, page + 1)}
                disabled={
                  page >= Math.ceil(count / rowsPerPage) - 1
                }
              >
                <KeyboardArrowRight />
              </IconButton>
            )}
          </Box>
        </Box>
      </Paper>
      
    </>
  );
};

export default VoucherList;
