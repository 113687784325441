import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Chip, MenuItem, Select, InputLabel, FormControl, Box, FormHelperText, Grid, CircularProgress } from '@mui/material';
import OrderServices from '../../../api/orderServices/order.index';
import { useNavigate } from 'react-router-dom';
import { ErrorToaster, SuccessToaster } from '../../../components/Toaster';
import instance from '../../../../axios';
import UploadIcon from '@mui/icons-material/Upload';



const CreateCollection = () => {
  const navigate = useNavigate()
  const [chips, setChips] = useState(['Cats', 'Cars']);
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [Loader, setLoader] = useState(false)

  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageUpload = (event) => {
    setLoader(true)
    const file = event.target.files[0];
    let arr = [
      {
        name: file?.name,
        file: "",
        type: file?.type.split("/")[1],
        size: file.size,
        isUpload: false,
      },
    ];

    if (file) {
      const reader = new FileReader();
      handleUpload(file, arr)

      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async (file, docs) => {
    setProgress(0);
    try {
      const formData = new FormData();
      formData.append("image", file);
      console.log(file);
      const { data } = await instance.post('/system/uploadImage', formData, {
        onUploadProgress: (progressEvent) => {
          const uploadedBytes = progressEvent.loaded;
          const percentCompleted = Math.round(
            (uploadedBytes * 100) / progressEvent.total
          );

          setProgress(percentCompleted);

        },
      });
      if (data) {
        docs[0].isUpload = true;
        docs[0].file = data?.data?.nations;
        console.log(data);

        setUploadedImage(data?.data?.path);
        setLoader(false)
        console.log(data, 'asddasasd');
        return data?.data?.path

      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      categories: '',
    },
  });

  const getCategories = async () => {
    setLoading(true);
    try {
      const { data, responseCode, message } = await OrderServices.getCollectionCategories();
      console.log(data.orders);
      setCategories(data.categories);
      setLoading(false);

    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  const onSubmit = async (formData) => {

    formData.selectedChips = chips; // Attach chips to form data
    console.log(formData);


    try {
      let obj = {
        title: formData?.title,
        category_id: formData?.categories,
        description: formData?.description,
        tags: formData.selectedChips,
        image: uploadedImage


      }
      console.log(obj);
      const promise = OrderServices.AddCollection(obj);


      const response = await promise;
      if (response?.responseCode === 200) {
        navigate('/collection-list')
        SuccessToaster(response?.message)
      }






    } catch (error) {

    }
  }

  const handleDeleteChip = (chipToDelete) => {
    setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
  };

  const handleAddChip = (event) => {
    if (event.key === 'Enter' && event.target.value) {
      setChips((prevChips) => [...prevChips, event.target.value]);
      event.target.value = ''; // Clear the input after adding
    }
  };
  useEffect(() => {
    getCategories()
  }, [])


  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '120px' }}>
      <Grid container spacing={2}>
        {/* Title Field */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            {...register('title', { required: 'Title is required' })}
            error={!!errors.title}
            helperText={errors.title ? errors.title.message : ''}
          />
        </Grid>

        {/* Categories Select Field */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.categories}>
            <InputLabel id="categories-label">Select Category</InputLabel>
            <Select
              labelId="categories-label"
              label="Categories"
              defaultValue=""
              {...register('categories', { required: 'Category is required' })}
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category?._id}>
                  {category?.name}
                </MenuItem>
              ))}
            </Select>
            {errors.categories && <FormHelperText>{errors.categories.message}</FormHelperText>}
          </FormControl>
        </Grid>

        {/* Chip Input Field */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Add Tags"
            variant="outlined"
            fullWidth
            onKeyDown={handleAddChip}
            placeholder="Press Enter to add a tag"
          />
        </Grid>

        {/* Display Chips */}
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {chips.map((chip, index) => (
              <Chip key={index} label={chip} onDelete={() => handleDeleteChip(chip)} />
            ))}
          </Box>
        </Grid>

        {/* Description Field */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            {...register('description')}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ''}
          />
        </Grid>

        {/* Image Upload Field */}
        <Grid item xs={12} md={6} display={'flex'} gap={2}>
  <Box
    sx={{
      position: 'relative',
      width: '200px', // Set the width and height as per your design
      height: '200px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      overflow: 'hidden',
      '&:hover .overlay': {
        opacity: 1, // Show overlay on hover
      },
    }}
  >
    {uploadedImage ? (
      <img
        src={uploadedImage}
        alt="Uploaded"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    ) : (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f0f0',
        }}
      >
        Upload Image
      </Box>
    )}

    {/* Hidden input for uploading image */}
    <input
      accept="image/*"
      type="file"
      {...register('image', {
        required: uploadedImage ? false : 'Image is required',
      })}
      onChange={(event) => {
        handleImageUpload(event); // Handle the file upload logic
        setValue('image', event.target.files); // Set the file in the hook form
      }}
      style={{ display: 'none' }}
      id="upload-button-input"
    />

    {/* Overlay with Upload button */}
    <Box
      className="overlay"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        opacity: 0, // Initially hidden
        transition: 'opacity 0.3s ease', // Smooth transition
      }}
    >
      <label style={{cursor:'pointer'}} htmlFor="upload-button-input">
        {Loader ? <CircularProgress /> : <UploadIcon/>}
      </label>
    </Box>
  </Box>

  {/* Error message for validation */}
  {errors?.image && (
    <FormHelperText error>{errors?.image?.message}</FormHelperText>
  )}
</Grid>




        {/* Submit Button */}
        <Grid item xs={12}>
          <Button sx={{color:'white'}} variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>

  );
};

export default CreateCollection;
