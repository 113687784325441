import { Avatar, Box, Button, Grid, Paper, Typography, styled } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import OrderServices from '../../../api/orderServices/order.index';
import InfoIcon from '@mui/icons-material/Info';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const OrderDetail = () => {
  const { state } = useLocation()
  const [tshirtPrice, setTshirtPrice] = useState(0)
  console.log(state);

  const getPricing = async () => {
    try {
      let params = {

      }
      const { data } = await OrderServices.getPricing(params)
      console.log(data);
      setTshirtPrice(data?.pricing?.standard_price)


    } catch (error) {
      console.log(error);
    } finally {

    }
  }
  const downloadImage = async (url) => {
    try {
      let params = {
        path:url
      }
      const data = await OrderServices.downloadImage(params)
      console.log(data?.url);
      window.open(data?.url, '_blank');


    } catch (error) {
      console.log(error);
    } finally {

    }
  }

  useEffect(() => {
    getPricing()

  }, [])

  return (
    <div style={{ marginTop: '100px' }}>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          Order ID: {state?.order_no}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={10}>
            <Typography variant="body1" gutterBottom>
              Order date: {moment().format('DD-MM-YYYY')}
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2, gap: '10px' }}>

              <Button onClick={() => {
                downloadImage(state?.details[0]?.design_front)

              }} variant="contained" color="secondary">
                Download Front Design
              </Button>
              <Button onClick={() => {
                downloadImage(state?.details[0]?.design_back)
              }} variant="contained" color="secondary">
                Download Back Design
              </Button>
            </Box>

            {/* <Typography variant="body1" gutterBottom>
            Estimated delivery: May 14, 2022
          </Typography> */}
            <Grid container spacing={2}>
              {state?.details?.map((item, index) => (
                <Grid item xs={12} md={6} lg={6} key={index}>
                  <Item sx={{ display: 'flex', justifyContent: 'space-between', boxShadow: 'none !important', border: '1px solid skyblue' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar sx={{ bgcolor: deepPurple[500] }}>{item.size}</Avatar>&nbsp;
                      <Box>
                        <Typography variant="h6">{item?.size == "S" ? 'Small' : item?.size == 'M' ? 'Medium' : item?.size == 'L' ? 'Large' : 'Extra Large'}</Typography>
                        {/* <Typography variant="body2" color="text.secondary">
                        {item.specs}asdasd
                      </Typography> */}
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6" color="primary">
                        Price {tshirtPrice}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="text.secondary">
                        Qty: {item.qty}
                      </Typography>
                      <Typography variant="h6" color="primary">
                        AED {item.qty * tshirtPrice}
                      </Typography>

                    </Box>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Grid>


        </Grid>
        <Box sx={{ flexGrow: 1, mt: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                Payment
              </Typography>
              <Typography variant="body2" gutterBottom>
                Visa **56
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                Delivery</Typography>
              <Typography variant="body2" gutterBottom>
                Address:{state?.address?.address_1 + ',' + state?.address?.address_2}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Phone: {state?.customer?.phone}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Delivery method: Free (30 days)
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                Order Summary
              </Typography>
              <Typography variant="body2" gutterBottom>
                Subtotal: AED {state?.payment_amount}
              </Typography>


              <Typography variant="body2" gutterBottom>
                Tax: +AED {parseFloat(state?.payment_amount * 0.05).toFixed(2)}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Total: AED {parseFloat(state?.payment_amount + state?.payment_amount * 0.05).toFixed(2)}
              </Typography>
              <Typography sx={{ fontFamily: "Noto Sans, sans-serif", mb: 2, fontWeight: 'bold' }}>
                Design Preview
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box component={'img'} sx={{ width: '300px' }} src={state?.screenshot_front}></Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box component={'img'} sx={{ width: '300px' }} src={state?.screenshot_back}></Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  )
}

export default OrderDetail
