import { Box, Button, Grid, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SuccessToaster } from '../../../components/Toaster'
import OrderServices from '../../../api/orderServices/order.index'
import { useForm } from 'react-hook-form'

const CollectionCatUpdate = () => {
    const {state}=useLocation()
    console.log(state);
    
    const navigate = useNavigate()
    const { control, handleSubmit, register, formState: { errors },setValue } = useForm();
    const onSubmit = async (formData) => {

        try {
            let obj = {
                name: formData?.collection,
               


            }
            let params ={
                id:state?._id
            }
            console.log(obj);
            const promise = OrderServices.UpdateCategory(obj,params);


            const response = await promise;
            if (response?.responseCode === 200) {
                navigate('/collections-category-list')
                SuccessToaster(response?.message)
            }






        } catch (error) {

        }
    }
    useEffect(() => {
        setValue('collection',state?.name)
      
    }, [])
    

    return (
        <div style={{ margin: '30px', marginTop: '120px', }}>
            <Grid container >


                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ display: 'flex',flexDirection:'column' }}
                >
                    <TextField
                        {...register("collection", { required: "Collection name is required" })}
                        label="Category Name"
                        size="small"
                        variant="outlined"
                        margin="normal"
                        error={!!errors.collection}
                        helperText={errors.collection ? errors.collection.message : ""}
                    />
                    <Box mt={2}>
                        <Button type="submit" sx={{ color: 'white' }} variant="contained" color="primary">
                            Update
                        </Button>
                    </Box>
                </form>
            </Grid>
        </div>
    );
}

export default CollectionCatUpdate
