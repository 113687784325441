export const  OrderRoutes = {
   
    getOrders: "/order/listing",
    updateStatus:"/order/update",
    getPricing:'system/pricing',
    updateSettings:'system/updatePricing',
    downloadImage:'download-mediaV2',
    AddCategory:'/collections/categories/create',
    getCollectionCategories:"/collections/categories",
    UpdateCategory:'/collections/categories/update',
    deleteCollectionCat:'/collections/categories/delete',
    AddCollection:'/collections/create',
    getCollectionList:'/collections',
    deleteCollection:"/collections/delete",
    UpdateCollection:'/collections/update'
    
    
  };
    