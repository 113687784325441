import { OrderRoutes } from "./order.route";
import { post, get, put,patch, deleted } from "../index";


const OrderServices = {
  getOrders: async () => {
    let result = get(OrderRoutes.getOrders );
    return result;
  },
  downloadImage: async (params) => {
    let result = get(OrderRoutes.downloadImage,params );
    return result;
  },
  getCollectionList: async (params) => {
    let result = get(OrderRoutes.getCollectionList,params );
    return result;
  },
  deleteCollectionCat: async (params) => {
    let result = deleted(OrderRoutes.deleteCollectionCat,params );
    return result;
  },
  deleteCollection: async (params) => {
    let result = deleted(OrderRoutes.deleteCollection,params );
    return result;
  },
  getPricing: async () => {
    let result = get(OrderRoutes.getPricing );
    return result;
  },
  getCollectionCategories: async () => {
    let result = get(OrderRoutes.getCollectionCategories );
    return result;
  },
  updateStatus: async (obj) => {
    
    let result = patch(OrderRoutes.updateStatus,obj);
    return result;
  },
  UpdateCategory: async (obj,params) => {
    
    let result = patch(OrderRoutes.UpdateCategory,obj,params);
    return result;
  },
  UpdateCollection: async (obj,params) => {
    
    let result = patch(OrderRoutes.UpdateCollection,obj,params);
    return result;
  },
  updateSettings: async (obj) => {
    
    let result = post(OrderRoutes.updateSettings,obj);
    return result;
  },
  AddCollection: async (obj) => {
    
    let result = post(OrderRoutes.AddCollection,obj);
    return result;
  },
  AddCategory: async (obj) => {
    
    let result = post(OrderRoutes.AddCategory,obj);
    return result;
  },
};

export default OrderServices;
