import { VouchersRoutes } from "./route";
import { post, get, put, deleted, patch } from "../index";


const AuthServices = {
  createVoucher: async (obj) => {
    let result = post(VouchersRoutes.createVoucher ,obj );
    return result;
  },
  updateVoucher: async (obj) => {
    let result = patch(VouchersRoutes.updateVoucher ,obj );
    return result;
  },
  voucherList: async (page , limit) => {
    let result = get(VouchersRoutes.voucherList + `?page=${page}&limit=${limit}` );
    return result;
  },
  deleteVoucher: async (id) => {
    let result = deleted(VouchersRoutes.deleteVoucher + `?id=${id}` );
    return result;
  },
  
};

export default AuthServices;
