import React, { Fragment, useState } from "react";
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardMedia,
  IconButton,
  Collapse,
} from "@mui/material";
import Colors from "../../../assets/styles";
import styled from "@emotion/styled";
import Images from "../../../assets/images";
import { useNavigate, useLocation } from "react-router-dom";
import Navigation from "../../../../Navigation";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const drawerWidth = 270;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 16,
  ...theme.mixins.toolbar,
}));

export default function SideNav({ status, toggleStatus }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setName } = useBreadCrumb();
  const [expand, setExpand] = useState([]);
  console.log("🚀 ~ SideNav ~ expand:", expand);

  const handleToggleSubMenu = (name) => {
    setExpand((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          transition: "all .3s ease-in-out",
          width: status == false ? drawerWidth : 50,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            transition: "all .3s ease-in-out",
            width: status == false ? drawerWidth : 0,
            boxSizing: "border-box",
            background: Colors.dashboardBgColor,
            borderRight: "1px solid rgb(193 192 192 / 13%)",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <DrawerHeader>
          <CardMedia
            component={"img"}
            src={Images.logo}
            sx={{
              width: "110px",
              objectFit: "contain",
            }}
          />
        </DrawerHeader>
        <Divider
          className="divider"
          sx={{ borderColor: "rgba(193, 192, 192, 12%)" }}
        />
        <List sx={{ px: "19px", py: 2 }}>
          {Navigation.map((item, index) => {
            const isSelected = item.path === pathname;
            return item.name === "Hardcoded" ? (
              <ListItem
                key={index}
                sx={{
                  p: "12px 16px",
                  borderRadius: "12px",
                  backgroundColor: isSelected ? Colors.primary : "transparent",
                  transition: "background-color 0.3s ease",
                  ":hover": {
                    backgroundColor: Colors.primaryLight,
                  },
                }}
              >
                <ListItemText
                  primary={item.text}
                  sx={{
                    span: {
                      fontSize: "16px",
                      fontWeight: 700,
                      textTransform: "uppercase",
                      color: isSelected ? Colors.white : Colors.black,
                    },
                  }}
                />
              </ListItem>
            ) : item.subMenu ? (
              <Fragment key={index}>
                <ListItem
                  sx={{
                    p: 0,
                    borderRadius: "12px",
                    backgroundColor: expand[item.name] ? Colors.white : "transparent",
                    boxShadow: expand[item.name] ? "0 4px 8px rgba(0,0,0,0.1)" : "none",
                    transition: "all 0.3s ease",
                  }}
                >
                  <ListItemButton
                    onClick={() => handleToggleSubMenu(item.name)}
                    sx={{
                      p: "12px 16px",
                      borderRadius: "12px",
                      backgroundColor: expand[item.name] ? Colors.primaryLight : "transparent",
                      ":hover": {
                        backgroundColor: Colors.primaryLighter,
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "52px" }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: "18px",
                          color: expand[item.name] ? Colors.white : Colors.secondary,
                          borderRadius: "50%",
                          p: "7px",
                          backgroundColor: expand[item.name] ? Colors.secondary : Colors.white,
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        },
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      sx={{
                        color: expand[item.name] ? Colors.black : Colors.grey,
                        span: {
                          fontWeight: 600,
                          fontSize: "14px !important",
                        },
                      }}
                    />
                    {expand[item.name] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={expand[item.name]} timeout="auto" unmountOnExit>
                  <List sx={{ p: "8px 0px" }}>
                    {item.subMenu.map((subMenu, subInd) => (
                      <ListItem key={subInd} sx={{ p: "0px 8px" }}>
                        <ListItemButton
                          sx={{
                            p: "8px",
                            borderRadius: "8px",
                            ":hover": {
                              backgroundColor: Colors.primaryLighter,
                            },
                          }}
                          onClick={() => {
                            navigate(subMenu.path);
                            setName(subMenu.name);
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: "52px", justifyContent: "center" }}>
                            {React.cloneElement(subMenu.icon, {
                              sx: {
                                color: pathname === subMenu.path ? Colors.secondary : Colors.grey,
                                fontSize: pathname === subMenu.path ? "12px" : "10px",
                              },
                            })}
                          </ListItemIcon>
                          <ListItemText
                            primary={subMenu.name}
                            sx={{
                              span: {
                                color: pathname === subMenu.path ? Colors.black : Colors.grey,
                                fontWeight: pathname === subMenu.path ? 600 : 400,
                                fontSize: "13px",
                              },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <Fragment key={index}>
                <ListItem
                  sx={{
                    p: 0,
                    pb: 2,
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      navigate(item.path);
                      setName(item.name);
                    }}
                    sx={{
                      p: "12px 16px",
                      borderRadius: "12px",
                      backgroundColor: isSelected ? Colors.primaryLight : "transparent",
                      ":hover": {
                        backgroundColor: Colors.primaryLighter,
                      },
                      boxShadow: isSelected
                        ? "0 4px 8px rgba(0, 0, 0, 0.1)"
                        : "none",
                      transition: "all 0.3s ease",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "52px" }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: "18px",
                          color: isSelected ? Colors.white : Colors.secondary,
                          borderRadius: "50%",
                          p: "7px",
                          backgroundColor: isSelected ? Colors.secondary : Colors.white,
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        },
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      sx={{
                        color: isSelected ? Colors.black : Colors.grey,
                        span: {
                          fontWeight: 600,
                          fontSize: "14px !important",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Fragment>
            );
          })}
        </List>

      </Drawer>
    </Box>
  );
}
