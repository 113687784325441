import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, Grid } from '@mui/material';
import OrderServices from '../../../api/orderServices/order.index';
import { useNavigate } from 'react-router-dom';
import { SuccessToaster } from '../../../components/Toaster';

const CollectionsCategory = () => {
    const { control, handleSubmit, register, formState: { errors } } = useForm();
    const navigate = useNavigate()


    const onSubmit = async (formData) => {




        try {
            let obj = {
                name: formData?.collection,


            }
            console.log(obj);
            const promise = OrderServices.AddCategory(obj);


            const response = await promise;
            if (response?.responseCode === 200) {
                navigate('/collections-category-list')
                SuccessToaster(response?.message)
            }






        } catch (error) {

        }
    }

    return (
        <div style={{ margin: '30px', marginTop: '120px', }}>
            <Grid container >


                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ display: 'flex',flexDirection:'column' }}
                >
                    <TextField
                        {...register("collection", { required: "Collection name is required" })}
                        label="Category Name"
                        size="small"
                        variant="outlined"
                        margin="normal"
                        error={!!errors.collection}
                        helperText={errors.collection ? errors.collection.message : ""}
                    />
                    <Box mt={2}>
                        <Button type="submit" sx={{ color: 'white' }} variant="contained" color="primary">
                            Submit
                        </Button>
                    </Box>
                </form>
            </Grid>
        </div>
    );
};

export default CollectionsCategory;
