import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import VoucherServices from "../../../api/VoucherServices/index";
import {
  TextField,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Switch,
  FormControlLabel as SwitchFormControlLabel,
} from "@mui/material";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function UpdateVoucher() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const formatDate = (date) => {
    if (!date) return "";
    const formattedDate = new Date(date);
    return formattedDate.toISOString().slice(0, 16);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    register,
  } = useForm({
    defaultValues: {
      code: state?.code,
      discountType: state?.discount_type,
      discountValue: state?.discount_value,
      minOrderValue: state?.min_order_value,
      startDate: formatDate(state?.start_date),
      endDate: formatDate(state?.end_date),
      isActive: state?.is_active || false,
    },
  });

  const discountType = watch("discountType");
  const startDate = watch("startDate");

  const onSubmit = async (data) => {
    try {
      let obj = {
        id: state?._id,
        code: data?.code,
        discount_type: data?.discountType,
        discount_value: data?.discountValue,
        min_order_value: data?.minOrderValue,
        start_date: data?.startDate,
        end_date: data?.endDate,
        is_active: data?.isActive,
      };
      console.log(obj);
      const promise = VoucherServices.updateVoucher(obj);

      const response = await promise;
      if (response?.responseCode === 200) {
        navigate("/voucher-list");
        SuccessToaster(response?.message);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    if (discountType === "percentage") {
      setValue("discountValue", state?.discount_value);
    } else if (discountType === "fixed") {
      setValue("discountValue", state?.discount_value);
    }
  }, [discountType, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "120px" }}>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="code"
            control={control}
            rules={{ required: "Discount code is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Discount Code"
                variant="outlined"
                fullWidth
                error={!!errors.code}
                helperText={errors.code ? errors.code.message : ""}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl component="fieldset" fullWidth>
            <Typography variant="subtitle1">Discount Type</Typography>
            <Controller
              name="discountType"
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="percentage"
                    control={<Radio />}
                    label="Percentage"
                  />
                  <FormControlLabel
                    value="fixed"
                    control={<Radio />}
                    label="Fixed"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>

        {/* Discount Value */}
        {/* <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Discount Value"
            variant="outlined"
            fullWidth
            type="number"
            {...register("discountValue", { required: "Discount value is required" })}
            error={!!errors.discountValue}
            helperText={errors.discountValue ? errors.discountValue.message : ""}
            InputProps={{
              endAdornment: discountType === "percentage" ? "%" : "AED",
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="discountValue"
            control={control}
            rules={{
              required: "Discount value is required",
              min:
                discountType === "percentage"
                  ? {
                      value: 1,
                      message: "Minimum discount value is 1%",
                    }
                  : undefined,
              max:
                discountType === "percentage"
                  ? {
                      value: 99,
                      message: "Maximum discount value is 99%",
                    }
                  : undefined,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Discount Value"
                variant="outlined"
                fullWidth
                type="number"
                error={!!errors.discountValue}
                helperText={
                  errors.discountValue ? errors.discountValue.message : ""
                }
                InputProps={{
                  endAdornment: discountType === "percentage" ? "%" : "AED",
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="minOrderValue"
            control={control}
            rules={{ required: "Minimum order value is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Minimum Order Value (AED)"
                variant="outlined"
                fullWidth
                type="number"
                error={!!errors.minOrderValue}
                helperText={
                  errors.minOrderValue ? errors.minOrderValue.message : ""
                }
                InputProps={{
                  endAdornment: "AED",
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="startDate"
            control={control}
            rules={{ required: "Start date is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Start Date"
                variant="outlined"
                fullWidth
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.startDate}
                helperText={errors.startDate ? errors.startDate.message : ""}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="endDate"
            control={control}
            rules={{ required: "End date is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="End Date"
                variant="outlined"
                fullWidth
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: startDate,
                }}
                error={!!errors.endDate}
                helperText={errors.endDate ? errors.endDate.message : ""}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <Typography variant="subtitle1">Active Status</Typography>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <SwitchFormControlLabel
                  control={<Switch {...field} checked={field.value} />}
                  label={field.value ? "Active" : "Inactive"}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Button
            sx={{ color: "white" }}
            variant="contained"
            color="primary"
            type="submit"
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
