import React, { useEffect, useState } from "react";
import {
    Avatar,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Box,
    Typography,
    IconButton,
    useTheme,
    InputLabel,
    TextField,
    Grid,
    MenuItem,
    Button,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Dialog,
} from "@mui/material";
import { CheckCircle, Cancel, Autorenew } from "@mui/icons-material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Colors from "../../../assets/styles";
import orderServices from "../../../api/orderServices/order.index";
import Loader from "../../../components/Loader";
import { useForm } from "react-hook-form";
import SimpleDialog from "../../../components/Dialog";
import OrderServices from "../../../api/orderServices/order.index";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const CollectionsCatList = () => {
    const navigate = useNavigate()
    const [order, setOrder] = useState("asc");

    const [orderBy, setOrderBy] = useState("created_at");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortedRows, setSortedRows] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectStatus, setSelectStatus] = useState();
    const [selectedUser, setSelectedUser] = useState("");
    const [categories, setCategories] = useState([])

    // State for dialog
    const [open, setOpen] = useState(false);
    const [rowToDelete, setRowToDelete] = useState(null);

    // Open the confirmation dialog
    const handleDeleteClick = (row) => {
        setRowToDelete(row);
        setOpen(true);
    };

    // Close dialog
    const handleClose = () => {
        setOpen(false);
        setRowToDelete(null);
    };

    // Confirm delete and call the delete function
    const handleConfirmDelete = () => {
        // Call delete function with rowToDelete._id
        // deleteFunction(rowToDelete._id);
        handleClose();
    };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    console.log(baseUrl);
    const tableHead = [

        { name: "created_at", label: "Created At" },
        { name: "customer", label: "Category Name" },
        { name: "action", label: "Action" },
    ];

    useEffect(() => {
        handleGetOrders();
    }, []);

    useEffect(() => {
        handleSort(orderBy, order);
    }, [categories]);

    const handleGetOrders = async () => {
        setLoading(true);
        try {
            const { data, responseCode, message } = await orderServices.getCollectionCategories();
            console.log(data.orders);
            setCategories(data.categories);
            setLoading(false);

        } catch (error) {
            console.error("Error while fetching users:", error);
        }
    };
    const handleDeleteCategory = async () => {
        setLoading(true);
        let params = {
            id: rowToDelete?._id
        }
        try {
            const { data, responseCode, message } = await orderServices.deleteCollectionCat(params);
            handleGetOrders()
            handleClose();

        } catch (error) {
            console.error("Error while fetching users:", error);
        }
    };
    const handleSort = (column, direction) => {
        const isAsc = direction === "desc";
        const sorted = [...categories].sort((a, b) => {
            let aValue = a[column];
            let bValue = b[column];

            // Handle nested properties
            if (column === "customer") {
                aValue = `${a.customer.first_name} ${a.customer.last_name}`;
                bValue = `${b.customer.first_name} ${b.customer.last_name}`;
            } else if (column === "details") {
                aValue = a.details
                    .map((detail) => `${detail.size} ${detail.qty} ${detail.design}`)
                    .join(", ");
                bValue = b.details
                    .map((detail) => `${detail.size} ${detail.qty} ${detail.design}`)
                    .join(", ");
            }

            if (aValue < bValue) {
                return isAsc ? -1 : 1;
            }
            if (aValue > bValue) {
                return isAsc ? 1 : -1;
            }
            return 0;
        });

        console.log(direction);
        setSortedRows(sorted);
        setOrder(direction);
        setOrderBy(column);
    };

    const handleSortRequest = (column) => {
        const isAsc = orderBy === column && order === "asc";
        handleSort(column, isAsc ? "desc" : "asc");
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const renderStatusIcon = (status) => {
        switch (status) {
            case "Paid":
                return <CheckCircle style={{ color: "green" }} />;
            case "Canceled":
                return <Cancel style={{ color: "red" }} />;
            case "Refunded":
                return <Autorenew style={{ color: "grey" }} />;
            case "pending":
                return <Autorenew style={{ color: "grey" }} />;
            default:
                return null;
        }
    };
    useEffect(() => {
        if (selectedUser) {
            console.log(selectedUser);
            setValue("status", selectedUser.status);
            setSelectStatus(selectedUser.status);
        }
    }, [openDialog, selectedUser, setValue]);

    const handleStatusChange = (event) => {
        setSelectStatus(event.target.value);
        setValue("status", event.target.value);
    };
    const handleOpenDialog = (orderDetail) => {
        console.log(orderDetail);
        setOpenDialog(true);
        setSelectStatus(orderDetail.status);
        setSelectedUser(orderDetail);
    };

    const updateStatus = async (formData) => {
        let obj = {
            order_id: selectedUser?._id,
            status: formData?.status,
        };
        console.log(obj);
        try {
            const data = await OrderServices.updateStatus(obj);
            setLoading(true);
            SuccessToaster(data.message);
            setOpenDialog(false);
            handleGetOrders();
        } catch (error) {
            ErrorHandler(error);
            ErrorToaster(error?.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <Paper
                sx={{
                    boxShadow: "rgba(0, 0, 0, 0.02) 0px 3.5px 5.5px",
                    borderRadius: "15px",
                }}
            >
                <TableContainer sx={{ mt: 12 }}>
                    <Box sx={{ m: 2, display: "flex", justifyContent: "end" }}>
                      <Button onClick={()=> navigate('/collections-category')}  sx={{backgroundColor:'#0ea5ea',color:'white'}} color="primary">
                        Create Category
                      </Button>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHead.map((cell) => (
                                    <TableCell key={cell.name}>
                                        <TableSortLabel
                                            active={orderBy === cell.name}
                                            direction={orderBy === cell.name ? order : "asc"}
                                            onClick={() => handleSortRequest(cell.name)}
                                            sx={{
                                                svg: {
                                                    fontSize: "12px",
                                                    color: `${Colors.grey} !important`,
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: Colors.grey,
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {cell.label}
                                            </Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                ))}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={tableHead.length + 1}>
                                        <Loader width="40px" height="40px" />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                sortedRows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell>{moment(row.created_at).format("DD-MM-YYYY")}</TableCell>
                                            <TableCell>{row?.name}</TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', gap: 2 }}>
                                                    <Box component={'div'} sx={{ cursor: 'pointer' }} onClick={() => navigate(
                                                        `/collections-category-update`,
                                                        { state: row }
                                                    )}>
                                                        <EditIcon />
                                                    </Box>
                                                    <Box component={'div'} sx={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(row)}>
                                                        <DeleteIcon />
                                                    </Box>
                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>

                    {/* Confirmation Dialog */}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="confirm-dialog-title"
                        aria-describedby="confirm-dialog-description"
                    >
                        <DialogTitle id="confirm-dialog-title">Confirm Delete</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="confirm-dialog-description">
                                Are you sure you want to delete this item?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">No</Button>
                            <Button onClick={handleDeleteCategory} color="secondary" autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </TableContainer>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                        }}
                    >
                        <Typography>
                            Showing {page * rowsPerPage + 1} to{" "}
                            {Math.min((page + 1) * rowsPerPage, categories.length)} of{" "}
                            {categories.length} entries
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            {page !== 0 && (
                                <IconButton
                                    sx={{ border: "1px solid grey", p: 1 }}
                                    onClick={(e) => handleChangePage(e, page - 1)}
                                    disabled={page === 0}
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>
                            )}
                            <Box sx={{ display: "flex", gap: "5px" }}>
                                {[
                                    ...Array(Math.ceil(categories.length / rowsPerPage)).keys(),
                                ].map((pageNum) => (
                                    <Box
                                        style={{
                                            border: "1px solid grey",
                                            p: 1,
                                            borderRadius: "50%",
                                            minWidth: "40px",
                                            minHeight: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            backgroundColor: page === pageNum ? Colors.primary : "",
                                            color: page === pageNum ? Colors.white : Colors.black,
                                        }}
                                        key={pageNum}
                                        onClick={(e) => handleChangePage(e, pageNum)}
                                    >
                                        {pageNum + 1}
                                    </Box>
                                ))}
                            </Box>
                            {page !== Math.ceil(categories.length / rowsPerPage) - 1 && (
                                <IconButton
                                    sx={{ border: "1px solid grey", p: 1 }}
                                    onClick={(e) => handleChangePage(e, page + 1)}
                                    disabled={
                                        page >= Math.ceil(categories.length / rowsPerPage) - 1
                                    }
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
            </Paper>
            <SimpleDialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false);
                }}
                title="Update Status"
                selectedUser={selectedUser}
            >
                <Box component={"form"} onSubmit={handleSubmit(updateStatus)}>
                    <Grid sx={{ mt: 2 }} container spacing={2} alignItems="center">
                        {/* status */}
                        <Grid item xs={12} sm={12} sx={{ width: "100%" }}>
                            <InputLabel>Status</InputLabel>
                            <TextField
                                fullWidth
                                select
                                {...register("status")}
                                value={selectStatus}
                                onChange={handleStatusChange}
                            >
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="confirmed">Confirmed</MenuItem>
                                <MenuItem value="processing">Processing</MenuItem>
                                <MenuItem value="delivered">Delivered</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                            </TextField>
                        </Grid>

                        {/* Submit Button */}
                        <Grid item xs={12} sm={12}>
                            <Button
                                type="submit"
                                sx={{
                                    background: Colors.primary,
                                    color: Colors.white,
                                    padding: "5px 25px",
                                    "&:hover": {
                                        background: Colors.primary,
                                    },
                                }}
                            >
                                UPDATE
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleDialog>
        </>
    );
};

export default CollectionsCatList;
